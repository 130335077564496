

<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <h1>ALPHA</h1>                
        <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius:53px">
                <div>
                    <h1 class="text-primary">
                        <div class="pi pi-download mr-2" style="font-size: 2.3rem"></div>
                        {{'LABELS.document-downloading' | translate}}
                    </h1>
                </div>
            </div>
        </div>
    </div>
    
</div>

