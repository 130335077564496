import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { DocumentVersion } from 'src/app/model/document-version.model';
import { Document } from 'src/app/model/document.model';
import { DocumentService } from 'src/app/service/api/document.service';
import { ToastService } from 'src/app/service/utils/toast.service';

@Component({
  selector: 'app-download-documents',
  templateUrl: './download-documents.component.html',
  styleUrl: './download-documents.component.scss',
  providers: [DialogService, ToastService]
})
export class DownloadDocumentsComponent {

  loading: boolean = false;
  organizationId?: string | null;
  documentId?: string | null;
  documentVersionId?: string | null;
  document?: Document | null = null;
  documentVersion?: DocumentVersion | null;

  constructor(
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger,
    private dialogService: DialogService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
    this.organizationId = route.snapshot.paramMap.get('organizationId');
    this.documentId = route.snapshot.paramMap.get('documentId');
    this.documentVersionId = route.snapshot.paramMap.get('documentVersionId');
    
  }

  ngAfterContentInit(): void {
    this.loadDocument(this.documentId!)
    .then(() => this.downloadDocument(this.document!))
  }

  async loadDocument(documentId: string) {
    this.loading = true;

    if (this.organizationId != null) {
      try {
        const documentResponse = await lastValueFrom(this.documentService.get(this.organizationId, documentId));
        this.document = documentResponse.data
      } catch(e) {
        this.logger.error(e);
      }

    }
  }

  async downloadDocument(doc: Document) {
    if (this.organizationId != null) {
      try {
        const documentResponse = await lastValueFrom(this.documentService.downloadFile(this.organizationId, doc.id!, this.documentVersionId!));
        let url = window.URL.createObjectURL(documentResponse);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc.name!);
        link.click();

        window.URL.revokeObjectURL(url);
      } catch(e) {
        this.logger.info(JSON.stringify(e));
      }
    }
  }
}
